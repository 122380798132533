import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

class Logout extends Component {

  state = {
    redirect: false
   };

  constructor(props) {
      super(props)
      this.state = {
          users: [],
          message: null
      }
  }

  renderRedirect = () => {
    if(this.state.redirect){
     return (
       <Redirect
         to="/login"
         userInput={this.props.userInput}
       />
      );
    }
  };

  async componentDidMount() {
    setInterval(() => {
      const access_token = localStorage.getItem("access_token");
      localStorage.clear();
      if (access_token) {
        window.location.href = "https://"+ process.env.REACT_APP_COGNITO_APP_DOMAIN +"/logout";
      } else {
        this.setState({redirect: true});
      }
    }, 1000);
  }

  render() {
      return (
          <div>
            {this.renderRedirect()}
            Logging out...
          </div>
      );
  }

}

export default Logout;
