import * as React from 'react';
import { Voyager } from 'graphql-voyager';
import fetch from 'isomorphic-fetch';
import "./GraphQLVoyager.css";
import { Auth } from "aws-amplify";

export default function() {
  function introspectionProvider(query) {
    var access_token = localStorage.getItem("access_token");
    return fetch(process.env.REACT_APP_GRAPHQL_API_URL, {
      method: 'post',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": access_token ? access_token : null,
      },
      body: JSON.stringify({ query: query }),
    }).then(async function(response) {
      if(response.status === 401) {
        await Auth.signOut();
        return null;
      }

      return response.json()
    });
  }

  return (
    <div className="graphiql-container">
      <Voyager introspection={introspectionProvider}
      workerURI={process.env.PUBLIC_URL + '/voyager.worker.js'}/>
    </div>
  );
}
