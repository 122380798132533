import React, { Component } from 'react'
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";

class Callback extends Component {

  state = {
    redirect: false
   };

  constructor(props) {
      super(props)
      this.state = {
          users: [],
          message: null
      }
  }

  renderRedirect = () => {
    if(this.state.redirect){
     return (
       <Redirect
         to="/"
         userInput={this.props.userInput}
       />
      );
    }
  };

  async componentDidMount() {
      var user = await Auth.currentAuthenticatedUser();

      if (user) {
        var access_token = user.getSignInUserSession().getAccessToken().jwtToken;
        var refresh_token = user.getSignInUserSession().getRefreshToken().getToken();

        if(access_token && refresh_token) {
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("refresh_token", refresh_token);

          this.setState({ redirect: true });
        }
      }
  }

  render() {
      return (
          <div>
            {this.renderRedirect()}
            callback page
          </div>
      );
  }

}

export default Callback;
